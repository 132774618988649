<template>
  <div class="relative w-full font-Gilroy text-black">
    <Header />
    <div id="section-banner" class="relative">
      <img
        :src="require('@/assets/images/marketing/mobile/banner-home.png')"
        class="w-full"
      />
      <div class="absolute bannerButtonPositions">
        <div class="flex gap-4">
          <div @click.prevent="openModal()">
            <img
              :src="require('@/assets/images/marketing/button-watch-video.png')"
              class="h-12"
            />
          </div>
          <router-link
            :to="{
              name: 'Contact',
            }"
          >
            <img
              :src="require('@/assets/images/marketing/button-book-demo.png')"
              class="h-12"
            />
          </router-link>
        </div>
        <VideoModal @close="closeModal()" v-if="video" />
      </div>
    </div>
    <div id="section-create-exps" class="px-6 mt-6">
      <div class="flex justify-start">
        <img
          :src="require('@/assets/images/marketing/create-exps.png')"
          class="w-full"
        />
      </div>
      <div class="grid grid-cols-2 gap-4 mt-4">
        <div v-for="index in 5" :key="index">
          <img
            :src="
              require('@/assets/images/marketing/icon-create-exps-' +
                index +
                '.png')
            "
          />
        </div>
      </div>
    </div>
    <div id="section-journey" class="px-6 mt-10">
      <div class="flex justify-center">
        <img
          :src="require('@/assets/images/marketing/journey.png')"
          class="w-full"
        />
      </div>
      <div class="grid mt-10">
        <div class="grid grid-cols-2">
          <div>
            <img
              :src="require('@/assets/images/marketing/journey-deadzone.png')"
            />
          </div>
          <div class="flex flex-col justify-between">
            <div>
              <p class="text-sm font-Gilroy font-semibold">
                Candidate Engagement
              </p>
              <p class="text-xl font-GilroyBlack mt-2">
                Bring the <br /><span class="texperia-underline"
                  >dead zone</span
                >
                to life
              </p>
            </div>
            <div class="flex">
              <img
                :src="require('@/assets/images/marketing/journey-evp.png')"
                class="h-20"
              />
              <img
                :src="
                  require('@/assets/images/marketing/journey-welcome-video.png')
                "
                class="h-20"
              />
            </div>
          </div>
        </div>
        <div>
          <p class="mt-4 text-lg">
            The period between sending out an offer letter and the candidate's
            first day at work - the dead zone, as we call it - is crucial. For
            the best relationships to work, communication and engagement is the
            key and that's what we let you deliver!
          </p>
        </div>
      </div>
      <div class="grid mt-10">
        <div class="grid grid-cols-2">
          <div>
            <img
              :src="require('@/assets/images/marketing/journey-memorable.png')"
            />
          </div>
          <div class="flex flex-col justify-between">
            <div>
              <p class="text-sm font-Gilroy font-semibold">
                Employee Engagement
              </p>
              <p class="text-xl font-GilroyBlack mt-2">
                The first <br /><span class="texperia-underline"
                  >memorable</span
                >
                day
              </p>
            </div>
            <div class="flex">
              <img
                :src="require('@/assets/images/marketing/journey-nha.png')"
                class="h-20"
              />
              <img
                :src="
                  require('@/assets/images/marketing/journey-induction-video.png')
                "
                class="h-20"
              />
            </div>
          </div>
        </div>
        <div>
          <p class="mt-4 text-lg">
            Keep things A1 on Day 1 with a sprinkle of Texperia's immersive
            experiences. Don't drown your newbies in processes and paperwork.
            Instead, use smart tech to engage, connect, and prep them to become
            a part of your team!
          </p>
        </div>
      </div>
      <div class="grid mt-10">
        <div class="grid grid-cols-2">
          <div>
            <img
              :src="require('@/assets/images/marketing/journey-firstmonth.png')"
            />
          </div>
          <div class="flex flex-col justify-between">
            <div>
              <p class="text-xl font-GilroyBlack">
                Happy <br /><span class="texperia-underline">first month</span>
              </p>
            </div>
            <div class="flex">
              <img
                :src="
                  require('@/assets/images/marketing/journey-scavenger-hunt.png')
                "
                class="h-20"
              />
              <img
                :src="
                  require('@/assets/images/marketing/journey-7-day-pulse.png')
                "
                class="h-20"
              />
            </div>
          </div>
        </div>
        <div>
          <p class="mt-4 text-lg">
            The first month can create first impressions and strengthen employee
            retention. So this period must be packed with ice-breakers,
            nteractive surveys, and short activities that also motivate the most
            honest answers out of your team.
          </p>
        </div>
      </div>
    </div>
    <div id="section-why-stop" class="p-6">
      <div
        class="text-mColorBlue font-GilroyBlack text-2xl tracking-wide leading-snug"
      >
        Why stop at 30 days?
      </div>
      <p class="text-lg mt-4">
        Now that your candidates have become new hires, don't stop. Deliver<br />
        employee experiences all the way till 90 days. We've got you covered
        with<br />more experiences:
      </p>
      <img
        :src="require('@/assets/images/marketing/mobile/why-stop.png')"
        class="mt-4"
      />
      <p class="mt-4 text-base text-center">
        Watch out for this section for further updates!
      </p>
    </div>
    <div id="section-explore" class="p-6">
      <div
        class="text-mColorBlue font-GilroyBlack text-3xl tracking-wide leading-snug text-center"
      >
        Explore the Texperia experience
      </div>
      <p class="mt-4 text-lg text-center">
        The only multi-tech, multi-month platform making onboarding journeys
        experiential.
      </p>
      <div class="customize mt-4 grid rounded-3xl">
        <div class="flex gap-16 p-4">
          <div class="w-1/4">
            <span class="texperia-underline text-2xl font-GilroyBlack"
              >Customize</span
            >
          </div>
          <div class="w-3/4">
            <img
              :src="require('@/assets/images/marketing/icon-customize.png')"
              class="w-full"
            />
          </div>
        </div>
        <div class="px-4 pb-4">
          <p class="text-base font-Poppins">
            Divide the onboarding process into three stages and customize each
            stage according to your company's culture. From offer acceptance,
            the first day of work, and post-onboarding to surveys and
            compliance, make moments that matter. Create every candidate's
            journey enriching and super delightful!
          </p>
        </div>
      </div>
      <div class="configure mt-4 grid rounded-3xl">
        <div class="flex gap-16 p-4">
          <div class="w-1/4">
            <span class="texperia-underline text-2xl font-GilroyBlack"
              >Configure</span
            >
          </div>
          <div class="w-3/4">
            <img
              :src="require('@/assets/images/marketing/icon-configure.png')"
              class="w-full"
            />
          </div>
        </div>
        <div class="px-4 pb-4">
          <p class="text-base font-Poppins">
            Easily create immersive experiences for your employees that keep
            them engaged. You don't need a fancy tech team for execution. Follow
            the easy DIY steps and build the experience you wish to deliver.
            Else, we will do it for you.
          </p>
        </div>
      </div>
      <div class="personalize mt-4 grid rounded-3xl">
        <div class="flex gap-16 p-4">
          <div class="w-1/4">
            <span class="texperia-underline text-2xl font-GilroyBlack"
              >Personalize</span
            >
          </div>
          <div class="w-3/4">
            <img
              :src="require('@/assets/images/marketing/icon-personalize.png')"
              class="w-full"
            />
          </div>
        </div>
        <div class="px-4 pb-4">
          <p class="text-base font-Poppins">
            Your candidate is not just another hire; your team members are not
            just people in an office but your rockstars. They are super vital
            for your organisation's growth. Deliver positive digital experiences
            to them through personalized videos, games, polls, communication,
            and surveys. If you are a hybrid company, Texperia works wonders.
          </p>
        </div>
      </div>
      <div class="automate mt-4 grid rounded-3xl">
        <div class="flex gap-16 p-4">
          <div class="w-1/4">
            <span class="texperia-underline text-2xl font-GilroyBlack"
              >Automate</span
            >
          </div>
          <div class="w-3/4">
            <img
              :src="require('@/assets/images/marketing/icon-automate.png')"
              class="w-full"
            />
          </div>
        </div>
        <div class="px-4 pb-4">
          <p class="text-base font-Poppins">
            Plan your communication in advance and automate them. Create
            journeys based on your employee grades and deliver experiences every
            time you onboard a candidate on your ATS or an employee on your
            HRMS. What's more? You save time and resources for the HR team! And
            give them bragging rights.
          </p>
        </div>
      </div>
    </div>
    <div id="section-integrations" class="p-6">
      <div
        class="text-mColorDarkGray font-GilroyBlack text-4xl tracking-wide leading-snug text-center"
      >
        Integrations
      </div>
      <p class="text-lg text-center">Making connections is just what we do!</p>
      <div class="mt-10 grid grid-cols-3 gap-4 justify-items-center">
        <img
          :src="require('@/assets/images/marketing/icon-whatsapp.png')"
          class="h-14"
        />
        <img
          :src="require('@/assets/images/marketing/icon-slack.png')"
          class="h-14"
        />
        <img
          :src="require('@/assets/images/marketing/icon-msteams.png')"
          class="h-14"
        />
      </div>
      <div class="mt-10 grid grid-cols-2 justify-items-center">
        <img
          :src="require('@/assets/images/marketing/icon-ats.png')"
          class="h-14"
        />
        <img
          :src="require('@/assets/images/marketing/icon-hrms.png')"
          class="h-14"
        />
      </div>
    </div>
    <div id="demo-banner" class="overflow-hidden">
      <img
        :src="require('@/assets/images/marketing/mobile/banner-demo.png')"
        class="w-full transform scale-125 my-4"
      />
    </div>
    <div id="section-faqs" class="p-6">
      <div
        class="text-mColorBlue font-GilroyBlack text-5xl tracking-wide leading-snug text-center"
      >
        FAQs
      </div>
      <Accordion :items="faqs" :defaultIndex="defaultFAQIndex" />
    </div>
    <div id="banner-book-demo" class="p-6">
      <router-link
        :to="{
          name: 'Contact',
        }"
      >
        <img
          :src="
            require('@/assets/images/marketing/mobile/banner-book-demo.png')
          "
          class="w-full"
        />
      </router-link>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Marketing/Mobile/Common/Header.vue";
import Footer from "@/components/Marketing/Mobile/Common/Footer.vue";
import VideoModal from "@/components/Marketing/VideoModal.vue";
import Accordion from "@dzangolab/vue-accordion";
import "@dzangolab/vue-accordion/dist/accordion.css";

export default {
  name: "Home",
  props: ["faqs"],
  components: { Header, Footer, Accordion, VideoModal },
  data() {
    return {
      defaultFAQIndex: 1,
      video: false,
    };
  },
  created() {},
  updated() {},
  methods: {
    openModal() {
      this.video = true;
      document.body.style.overflow = "hidden";
    },
    closeModal() {
      this.video = false;
      document.body.style.overflow = "visible";
    },
  },
};
</script>
<style scoped>
.darkGrayText {
  color: #231e20;
}
.darkGrayBG {
  background: #231e20;
}
.darkGrayBorder {
  border: 2px solid#231e20;
}
.darkGrayBorderBottom {
  border: 2px solid#231e20;
}
.texperia-underline {
  position: relative;
}
.texperia-underline::before {
  content: "";
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
  height: 5px;
  border-radius: 2px;
  background: linear-gradient(
    45deg,
    #11bc1d 10%,
    #3d50e0 50%,
    #fdb400 75%,
    #ff5670 100%
  );
}
.customize {
  background: #fbe8e8;
}
.configure {
  background: #cafbe9;
}
.personalize {
  background: #ffe8bb;
}
.automate {
  background: #d5f1fc;
}

.c-accordion {
  border: 1px solid #898989;
  border-radius: 5px;
}
.c-accordion__content {
  border-top: 1px solid #cacaca;
  padding: 1rem 1rem;
}
.c-accordion .c-accordion__item {
  border-bottom: 1px solid #cacaca;
  margin: 0 0 0 0;
}
.c-accordion .c-accordion__item:last-child {
  border-bottom: none;
}
.c-accordion .c-accordion__item.-active .c-accordion__title {
  background-color: #cafbe9 !important;
}
.bannerButtonPositions {
  bottom: 6%;
  left: 8%;
}
</style>
