<template>
  <div class="grid">
    <Desktop :faqs="faqs" class="hidden md:block" />
    <Mobile :faqs="faqs" class="block md:hidden" />
  </div>
</template>

<script>
import Desktop from "@/components/Marketing/Desktop/Home.vue";
import Mobile from "@/components/Marketing/Mobile/Home.vue";

export default {
  name: "Home",
  components: { Desktop, Mobile },
  data() {
    return {
      faqs: [
        {
          title: "What does Texperia mean?",
          content:
            "Texperia is a world of technology-based experiences. There are hardly any players today trying to focus on the importance of a holistic and immersive experience of joining a company. We are filling that gap with the Texperia Touch!",
        },
        {
          title: "How and where do these experiences get delivered?",
          content:
            "All experiences are web and mobile compatible. Links are sent to the users on their email and/or whatsapp. A simple login will give them access to the customized experiences. SSO can be enabled to make it even simpler.",
        },
        {
          title: "How many experiences or journeys can I create?",
          content:
            "Journeys: Separate journeys can be created for different sets of candidates and employees. E.g.: A 30-day and a 60-day offer to join journeys, journeys based on employee grade, etc",
        },
        {
          title: "Do I need to be creative and tech savvy?",
          content:
            "Absolutely not. Texperia is a very simple DIY, no code platform that has very simple workflows. After a couple of journeys, you will be able to ace it. We will work with you initially to help create these experiences till you get a hang of it.",
        },
        {
          title:
            "Will my employee spend more time on your experiences than their work?",
          content:
            "If you deliver all our experiences to a candidate, the maximum time spent is a maximum of 2.5 hours in a 37 day journey (30 days offer to join + 7 days post joining).",
        },
        {
          title: "Can I use these experiences beyond onboarding?",
          content:
            "Ofcourse! If you find a feature/ tech exciting enough to deliver to your employees for another reason/ occasion, just create a new journey, customize the experience accordingly and get it delivered to a specific set of employees.",
        },
        {
          title: "How many experiences do you have?",
          content:
            "Texperia will always keep identifying new technologies and even moments that matter and offering it to you with recommendations. We will notify you and you could add them to the next journey you are creating.",
        },
      ],
    };
  },
  created() {},
  updated() {},
  methods: {},
};
</script>
<style scoped></style>
