<template>
  <div class="modal">
    <!-- Modal -->
    <div class="modal-wrapper fixed inset-0 overflow-y-auto">
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-52 md:pb-20 text-center sm:block sm:p-0"
      >
        <div
          @click.prevent="close()"
          class="fixed inset-0 transition-opacity"
          aria-hidden="true"
        >
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <div
          class="inline-block align-bottom bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white">
            <iframe
              :src="videoLink"
              title="Texperia"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowfullscreen
              class="videoFrame"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "VideoModal",
  data() {
    return {
      videoLink: "https://www.youtube.com/embed/94P3PEo6R_c",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-wrapper {
  z-index: 99999;
}
.videoFrame {
  width: 360px;
  height: 203px;
}
@media (min-width: 768px) {
  .videoFrame {
    width: 520px;
    height: 315px;
  }
}
</style>
